import { ParsedUrlQuery } from "querystring";

import locations from "../../../constants/finder/locations";
import { Location } from "../../../custom-types/Location";
import { radiusToZoom } from "../mapUtils";

export default (
  query: ParsedUrlQuery,
  finderLocation: Location | undefined,
) => {
  // We want to use lon instead of lng for consistency across
  // the app, but will support both here for legacy urls
  const { lat, lng, lon, zoom } = query;

  return {
    center: {
      lat: lat && (lon || lng) ? Number(lat) : finderLocation?.coordinates?.lat,
      lon:
        lat && (lon || lng)
          ? Number(lon || lng)
          : finderLocation?.coordinates?.lon,
    },
    zoom: processedZoom(finderLocation, zoom),
  };
};

function processedZoom(
  finderLocation: Location | undefined,
  zoom: string | string[] | undefined,
) {
  const radius = getCachedRadius(finderLocation);

  if (radius) {
    return radiusToZoom(radius);
  } else if (zoom) {
    return parseInt(`${zoom}`);
  }
  return 13;
}

function getCachedRadius(finderLocation: Location | undefined) {
  const normalizedLocationSlug = getNormalizedLocationSlug(finderLocation);
  return locations[normalizedLocationSlug as keyof typeof locations]?.radius;
}

function getNormalizedLocationSlug(finderLocation: Location | undefined) {
  const { city, geoSlug } = finderLocation || {};

  return finderLocation && !city
    ? geoSlug?.slice(0, geoSlug?.lastIndexOf("-us"))
    : geoSlug;
}
