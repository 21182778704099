import logError from "../../utils/logError";
import geocodeApi from "../services/geocode";

type GeolocationRequest =
  | {
      address: string;
    }
  | {
      placeId: string;
    };

export type GeolocationResult = {
  city: string;
  /*
   * It would be nice to use Coordinates here,
   * but that would require a migration since this is the type the leafly-location cookie uses.
   */
  coordinates: { latitude: string; longitude: string };
  country: string;
  country_code: string;
  formatted_location: string;
  place_id: string;
  slug: string;
  state: string;
  state_code: string;
  street: { name: string; number: string };
  sublocality: string;
  zip: string;
};

export const getGeolocation = async (
  request: GeolocationRequest,
): Promise<GeolocationResult | undefined> => {
  const params =
    "address" in request
      ? request.address === "george-washington"
        ? { lat: "47.0879045", lng: "-119.8590675" }
        : { address: request.address }
      : { placeId: request.placeId };

  try {
    const response = await geocodeApi.get("/", {
      params,
    });

    return response.data;
  } catch (err) {
    // 404 is expected if the geocode doesn't exist
    if (err.message !== "No results found") {
      logError(err.message, {
        functionName: "getGeolocation",
        service: "geocode",
        statusCode: err.statusCode,
      });
    }
  }
};

export default getGeolocation;
