import locations from "../../../constants/finder/locations";
import states from "../../../constants/states";
import { normalizeFinderLocation } from "../../locationUtils";

export default (city: string | undefined, state: string | undefined) => {
  const foundState = states.find((st) => st.slug === state);
  const stateCode = foundState?.short;

  if (isCityCached(city, stateCode)) {
    return normalizeFinderLocation(locations[`${city}-${stateCode}`]);
  } else if (isStateCached(city, state)) {
    return normalizeFinderLocation(locations[state as string]);
  }
  return undefined;
};

function isCityCached(city: string | undefined, stateCode: string | undefined) {
  return city && stateCode && locations[`${city}-${stateCode}`];
}

function isStateCached(city: string | undefined, state: string | undefined) {
  return !city && state && locations[state];
}
