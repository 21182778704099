export const mapStyles = [
  {
    elementType: "geometry",
    featureType: "administrative",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "geometry.fill",
    featureType: "landscape",
    stylers: [
      {
        color: "#f0f0f0",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "geometry.fill",
    featureType: "poi.park",
    stylers: [
      {
        color: "#d2e9d3",
      },
      {
        lightness: 25,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "labels.icon",
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "geometry.fill",
    featureType: "road.arterial",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "geometry.stroke",
    featureType: "road.arterial",
    stylers: [
      {
        color: "#e7e7e7",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "geometry.fill",
    featureType: "road.highway",
    stylers: [
      {
        color: "#dcdcdc",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "geometry.stroke",
    featureType: "road.highway",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        visibility: "on",
      },
      {
        weight: 2,
      },
    ],
  },
  {
    elementType: "labels.icon",
    featureType: "road.highway",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    featureType: "road.highway",
    stylers: [
      {
        color: "#333333",
      },
    ],
  },
  {
    elementType: "geometry.fill",
    featureType: "road.local",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "geometry.stroke",
    featureType: "road.local",
    stylers: [
      {
        color: "#e7e7e7",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "geometry.fill",
    featureType: "water",
    stylers: [
      {
        color: "#c8def2",
      },
      {
        saturation: 50,
      },
      {
        lightness: 30,
      },
      {
        visibility: "on",
      },
    ],
  },
];
