import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import { InitialLocationContext } from "custom-types/FinderPage";
import { Location } from "custom-types/Location";
import {
  DISABLE_LOCATION_CHANGE_RELOAD,
  ENABLE_LOCATION_CHANGE_RELOAD,
} from "redux/reducers/header";
import { getLocationState } from "redux/selectors/location";
import { getState } from "utils/finder/getContextData";
import {
  buildRoutePath,
  getLocationSlug,
  normalizeFinderLocation,
} from "utils/locationUtils";
import { clearAllQueryParams } from "utils/nextRouterUtils";

import MapContext from "./MapContext";

export type LocationContextProps = {
  finderLocation: Location;
};

export const locationContext = {
  finderLocation: {} as Location,
};

const LocationContext = createContext<LocationContextProps>(locationContext);

const addGeoSlug = (location?: Location) =>
  location
    ? {
        ...location,
        geoSlug: getLocationSlug({
          city: location?.city,
          state: location?.state || "",
          stateCode: location?.stateCode,
        }),
      }
    : {};

export const LocationProvider = ({
  data,
  children,
}: {
  data: InitialLocationContext;
  children: React.ReactNode;
}) => {
  const { query, push } = useRouter();

  const dispatch = useDispatch();

  const userLocation = useSelector(getLocationState);

  const { setBoundingBox } = useContext(MapContext);

  const isLocationPage = getState(query);

  const [finderLocation, setFinderLocation] = useState<Location>(
    addGeoSlug(data.finderLocation),
  );

  // If the user is on a location page (ie: /dispensaries/los-angeles/california),
  // we redirect them to the correct location page if the user changes their location
  // Disabling location reload allows us to do so
  useEffect(() => {
    if (isLocationPage) {
      dispatch({ type: DISABLE_LOCATION_CHANGE_RELOAD });

      return () => {
        dispatch({ type: ENABLE_LOCATION_CHANGE_RELOAD });
      };
    }
  }, [isLocationPage]);

  const currentUserLocationRef = useRef<Location>(userLocation);
  useEffect(() => {
    const currentUserLocation = currentUserLocationRef.current;

    if (
      currentUserLocation.coordinates?.lat !== userLocation.coordinates.lat ||
      currentUserLocation.coordinates?.lon !== userLocation.coordinates.lon
    ) {
      clearAllQueryParams(false, ["filter"]);
      setBoundingBox(); // This resets the map bounds, unsure if needed

      const normalizedUserLocation = normalizeFinderLocation(userLocation);

      if (normalizedUserLocation) {
        currentUserLocationRef.current = normalizedUserLocation;

        if (normalizedUserLocation !== finderLocation) {
          setFinderLocation(normalizedUserLocation);
        }
      }

      if (isLocationPage) {
        const { city, state } = userLocation;

        push(`/dispensaries${buildRoutePath({ city, state })}`);
      }
    }
  }, [JSON.stringify(userLocation)]);

  useEffect(() => {
    setFinderLocation(addGeoSlug(data.finderLocation));
  }, [JSON.stringify(data.finderLocation)]);

  return (
    <LocationContext.Provider
      value={{
        finderLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
