import { Location } from "custom-types/Location";

export const hasFullAddress = (location: Location): boolean => {
  if (!location) {
    return false;
  }

  const { street = null, city, state, zip } = location;

  if (!street?.name || !street?.number || !city || !state || !zip) {
    return false;
  }

  return [street.name, street.number, city, state, zip].every(
    (el) => el?.length && el.length > 0,
  );
};
