import consumerApi from "api/services/consumer-api";
import logError from "utils/logError";

type Organization = {
  slug: string;
  id: number;
  name: string;
};

const getOrganization = async (
  slug: string,
): Promise<Organization | undefined> => {
  try {
    const { data } = await consumerApi.get(`/api/organizations/v1/${slug}`);

    return data as Organization;
  } catch (e) {
    logError(e.message, {
      functionName: "getOrganization",
      service: "consumer",
      statusCode: e.statusCode,
    });
  }
};

export default getOrganization;
