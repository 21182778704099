import directus from "api/services/directus";
import { CopyBlock } from "custom-types/Directus";
import { filterByCountry } from "utils/finder/directusRequestUtils";
import logError from "utils/logError";

export const getCopyBlock = async (
  collection: string,
  slug: string,
  countryCode = "us",
  isDraft = false,
) => {
  const defaultCountryCode = "US";

  try {
    const result = await directus.get<CopyBlock[]>(collection, {
      params: {
        fields: "*,location.country",
        "filter[location][country][_in]": [
          countryCode.toUpperCase(),
          defaultCountryCode,
        ],
        "filter[slug][_eq]": slug,
      },
    });

    const data = filterByCountry(result.data, countryCode).find(
      (datum) => datum.status === (isDraft ? "draft" : "published"),
    );

    return (
      data || { copy_block: [], location: { country: countryCode }, status: "" }
    );
  } catch (e) {
    logError(e.message, {
      functionName: "getCopyBlock",
      service: "directus",
      statusCode: e.statusCode,
    });

    return null;
  }
};
