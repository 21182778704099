import { AxiosError, AxiosInstance } from "axios";

import publicConfig from "config/public";

import formatAndReturnRejectedError from "../../utils/formatAndReturnRejectedError";
import serviceRequest from "../serviceRequest";

// Initialize the service request with the app and environment
const geocodeApi: AxiosInstance = serviceRequest();

// Configure and set upstream URL
geocodeApi.defaults.baseURL = publicConfig.services.geoApi.url;

geocodeApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ error: string; message: string }>) =>
    formatAndReturnRejectedError(error, error.response?.data?.message),
);

export default geocodeApi;
