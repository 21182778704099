export const getStrainParams = (
  { strain, product_type }: { strain: string; product_type: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  availableFilters: any[],
  defaultToMenu = false,
) => {
  const strainParam = strain && `strain_name[]=${strain}`;

  const productCategoryParam = availableFilters
    .find((f) => f.param === "product_type")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    ?.nested.find((s: any) => s.param === product_type)?.display;
  const productTypeParam =
    productCategoryParam && `product_category[]=${productCategoryParam}`;

  switch (true) {
    case !!strainParam && !!productTypeParam:
      return `/menu?${strainParam}&${productTypeParam}`;
    case !!strainParam:
      return `/menu?${strainParam}`;
    case !!productTypeParam:
      return `/menu?${productTypeParam}`;
    case defaultToMenu:
      return "/menu";
    default:
      return "";
  }
};

export type GetStoreUrlParams = {
  storeRoute?: string;
  storeSlug?: string | null;
  strainFilters?: { strain: string; product_type: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  availableFilters?: any[];
  defaultToMenu?: boolean;
  hasMenu?: boolean;
};
// Routes to dispensary's landing page by default (/dispensary-info/:slug)
export const getStoreUrl = ({
  storeRoute,
  storeSlug,
  strainFilters,
  availableFilters = [],
  defaultToMenu = false,
  hasMenu = false,
}: GetStoreUrlParams) =>
  `${storeRoute ? storeRoute : ""}${storeSlug}${
    strainFilters
      ? encodeURI(
          getStrainParams(strainFilters, availableFilters, defaultToMenu),
        )
      : hasMenu
        ? "/menu"
        : ""
  }`;
