/**
 * Default locations used in finder
 * Used to reduce the gmaps location lookups by hardcoding
 * more popular location data.
 * */
export type GeoSlug = string;
export type StaticLocation = {
  city?: string;
  coordinates: { lat: number; lon: number };
  countryCode?: string;
  formattedLocation: string;
  radius: number;
  geoSlug: GeoSlug;
  slug?: string;
  state: string;
  stateCode: string;
};

const locations: Record<string, StaticLocation> = {
  "ada-ok": {
    city: "Ada",
    coordinates: { lat: 34.778193, lon: -96.665848 },
    countryCode: "US",
    formattedLocation: "Ada, OK",
    geoSlug: "ada-ok-us",
    radius: 5,
    state: "Oklahoma",
    stateCode: "OK",
  },
  "american-samoa": {
    coordinates: { lat: -14.298462, lon: -170.716407 },
    countryCode: "AS",
    formattedLocation: "American Samoa, USA",
    geoSlug: "American Samoa",
    radius: 150,
    slug: "american-samoa",
    state: "American Samoa",
    stateCode: "AS",
  },
  arizona: {
    coordinates: {
      lat: 34.0489281,
      lon: -111.0937311,
    },
    formattedLocation: "Arizona, USA",
    geoSlug: "arizona",
    radius: 255,
    state: "Arizona",
    stateCode: "AZ",
  },
  "atlanta-ga": {
    city: "Atlanta",
    coordinates: { lat: 33.7489954, lon: -84.3879824 },
    countryCode: "US",
    formattedLocation: "Atlanta, GA",
    geoSlug: "atlanta-ga-us",
    radius: 12,
    state: "Georgia",
    stateCode: "GA",
  },
  "austin-tx": {
    city: "Austin",
    coordinates: { lat: 30.267153, lon: -97.7430608 },
    countryCode: "US",
    formattedLocation: "Austin, TX",
    geoSlug: "austin-tx",
    radius: 21,
    state: "Texas",
    stateCode: "TX",
  },
  "baltimore-md": {
    city: "Baltimore",
    coordinates: { lat: 39.2903848, lon: -76.6121893 },
    countryCode: "US",
    formattedLocation: "Baltimore, MD",
    geoSlug: "baltimore-md-us",
    radius: 8,
    state: "Maryland",
    stateCode: "MD",
  },
  "bellingham-wa": {
    city: "Bellingham",
    coordinates: { lat: 48.7519112, lon: -122.4786854 },
    countryCode: "US",
    formattedLocation: "Bellingham, WA",
    geoSlug: "bellingham-wa-us",
    radius: 5,
    state: "Washington",
    stateCode: "WA",
  },
  "boston-ma": {
    city: "Boston",
    coordinates: { lat: 42.3600825, lon: -71.0588801 },
    countryCode: "US",
    formattedLocation: "Boston, MA",
    geoSlug: "boston-ma-us",
    radius: 13,
    state: "Massachusetts",
    stateCode: "MA",
  },
  california: {
    coordinates: { lat: 36.778261, lon: -119.4179324 },
    countryCode: "US",
    formattedLocation: "California, USA",
    geoSlug: "california",
    radius: 429,
    state: "California",
    stateCode: "CA",
  },
  "charlotte-nc": {
    city: "Charlotte",
    coordinates: { lat: 35.2270869, lon: -80.8431267 },
    countryCode: "US",
    formattedLocation: "Charlotte, NC",
    geoSlug: "charlotte-nc-us",
    radius: 17,
    state: "North Carolina",
    stateCode: "NC",
  },
  "chicago-il": {
    city: "Chicago",
    coordinates: { lat: 41.8781136, lon: -87.6297982 },
    countryCode: "US",
    formattedLocation: "Chicago, IL",
    geoSlug: "chicago-il-us",
    radius: 17,
    state: "Illinois",
    stateCode: "IL",
  },
  colorado: {
    coordinates: { lat: 39.5500507, lon: -105.7820674 },
    countryCode: "US",
    formattedLocation: "Colorado, USA",
    geoSlug: "colorado",
    radius: 232,
    state: "Colorado",
    stateCode: "CO",
  },
  "denver-co": {
    city: "Denver",
    coordinates: { lat: 39.7392358, lon: -104.990251 },
    countryCode: "US",
    formattedLocation: "Denver, CO",
    geoSlug: "denver-co-us",
    radius: 17,
    state: "Colorado",
    stateCode: "CO",
  },
  "detroit-mi": {
    city: "Detroit",
    coordinates: { lat: 42.331427, lon: -83.0457538 },
    countryCode: "US",
    formattedLocation: "Detroit, MI",
    geoSlug: "detroit-mi",
    radius: 11,
    state: "Michigan",
    stateCode: "MI",
  },
  florida: {
    coordinates: { lat: 27.6648274, lon: -81.5157535 },
    countryCode: "US",
    formattedLocation: "Florida, USA",
    geoSlug: "florida",
    radius: 325,
    state: "Florida",
    stateCode: "FL",
  },
  georgia: {
    coordinates: { lat: 32.664611, lon: -83.354083 },
    countryCode: "US",
    formattedLocation: "Georgia, USA",
    geoSlug: "georgia",
    radius: 197,
    state: "Georgia",
    stateCode: "GA",
  },
  guam: {
    coordinates: { lat: 15.188468639158321, lon: 145.74681897305973 },
    countryCode: "US",
    formattedLocation: "Guam, USA",
    geoSlug: "guam",
    radius: 325,
    state: "Guam",
    stateCode: "GU",
  },
  illinois: {
    coordinates: { lat: 40.6331249, lon: -89.3985283 },
    countryCode: "US",
    formattedLocation: "Illinois, USA",
    geoSlug: "illinois",
    radius: 225,
    state: "Illinois",
    stateCode: "IL",
  },
  "knoxville-tn": {
    city: "Knoxville",
    coordinates: { lat: 35.9606384, lon: -83.9207392 },
    countryCode: "US",
    formattedLocation: "Knoxville, TN",
    geoSlug: "knoxville-tn-us",
    radius: 16,
    state: "Tennessee",
    stateCode: "TN",
  },
  "las-vegas-nv": {
    city: "Las Vegas",
    coordinates: { lat: 36.1699412, lon: -115.1398296 },
    countryCode: "US",
    formattedLocation: "Las Vegas, NV",
    geoSlug: "las-vegas-nv-us",
    radius: 14,
    state: "Nevada",
    stateCode: "NV",
  },
  "los-angeles-ca": {
    city: "Los Angeles",
    coordinates: { lat: 34.0522342, lon: -118.2436849 },
    countryCode: "US",
    formattedLocation: "Los Angeles, CA",
    geoSlug: "los-angeles-ca-us",
    radius: 27,
    state: "California",
    stateCode: "CA",
  },
  maryland: {
    coordinates: { lat: 39.0457549, lon: -76.64127119999999 },
    countryCode: "US",
    formattedLocation: "Maryland, USA",
    geoSlug: "maryland",
    radius: 137,
    state: "Maryland",
    stateCode: "MD",
  },
  massachusetts: {
    coordinates: { lat: 42.4072107, lon: -71.3824374 },
    countryCode: "US",
    formattedLocation: "Massachusetts, USA",
    geoSlug: "massachusetts",
    radius: 111,
    state: "Massachusetts",
    stateCode: "MA",
  },
  michigan: {
    coordinates: { lat: 44.3148443, lon: -85.60236429999999 },
    countryCode: "US",
    formattedLocation: "Michigan, USA",
    geoSlug: "michigan",
    radius: 302,
    state: "Michigan",
    stateCode: "MI",
  },
  "new-jersey": {
    coordinates: { lat: 40.0583238, lon: -74.4056612 },
    countryCode: "US",
    formattedLocation: "New Jersey, USA",
    geoSlug: "new-jersey",
    radius: 100,
    state: "New Jersey",
    stateCode: "NJ",
  },
  "new-york": {
    coordinates: { lat: 43.2994285, lon: -74.21793260000001 },
    countryCode: "US",
    formattedLocation: "New York, USA",
    geoSlug: "new-york",
    radius: 330,
    state: "New York",
    stateCode: "NY",
  },
  "new-york-ny": {
    city: "New York",
    coordinates: { lat: 40.7830603, lon: -73.9712488 },
    countryCode: "US",
    formattedLocation: "New York, NY",
    geoSlug: "new-york-ny-us",
    radius: 22,
    state: "New York",
    stateCode: "NY",
  },
  "northern-mariana-islands": {
    coordinates: { lat: 15.188468639158321, lon: 145.74681897305973 },
    countryCode: "US",
    formattedLocation: "Northern Mariana Islands, USA",
    geoSlug: "northern-mariana-islands",
    radius: 330,
    state: "Northern Mariana Islands",
    stateCode: "MP",
  },
  ohio: {
    coordinates: { lat: 40.4172871, lon: -82.90712300000001 },
    countryCode: "US",
    formattedLocation: "Ohio, USA",
    geoSlug: "ohio",
    radius: 176,
    state: "Ohio",
    stateCode: "OH",
  },
  oklahoma: {
    coordinates: { lat: 35.0077519, lon: -97.092877 },
    countryCode: "US",
    formattedLocation: "Oklahoma, USA",
    geoSlug: "oklahoma",
    radius: 24,
    state: "Oklahoma",
    stateCode: "OK",
  },
  oregon: {
    coordinates: { lat: 43.8041334, lon: -120.5542012 },
    countryCode: "US",
    formattedLocation: "Oregon, USA",
    geoSlug: "oregon",
    radius: 250,
    state: "Oregon",
    stateCode: "OR",
  },
  pennsylvania: {
    coordinates: { lat: 41.2033216, lon: -77.1945247 },
    countryCode: "US",
    formattedLocation: "Pennsylvania, USA",
    geoSlug: "pennsylvania",
    radius: 179,
    state: "Pennsylvania",
    stateCode: "PA",
  },
  "philadelphia-pa": {
    city: "Philadelphia",
    coordinates: { lat: 39.9525839, lon: -75.1652215 },
    countryCode: "US",
    formattedLocation: "Philadelphia, PA",
    geoSlug: "philadelphia-pa-us",
    radius: 13,
    state: "Pennsylvania",
    stateCode: "PA",
  },
  "phoenix-az": {
    city: "Phoenix",
    coordinates: {
      lat: 33.4483771,
      lon: -112.0740373,
    },
    countryCode: "US",
    formattedLocation: "Phoenix, AZ",
    geoSlug: "phoenix-az-us",
    radius: 25,
    state: "Arizona",
    stateCode: "AZ",
  },
  "placitas-nm": {
    city: "Placitas",
    coordinates: { lat: 35.3069845, lon: -106.4247468 },
    countryCode: "US",
    formattedLocation: "Placitas, NM",
    geoSlug: "placitas-nm-us",
    radius: 10,
    state: "New Mexico",
    stateCode: "NM",
  },
  "portland-or": {
    city: "Portland",
    coordinates: { lat: 45.5051064, lon: -122.6750261 },
    countryCode: "US",
    formattedLocation: "Portland, OR",
    geoSlug: "portland-or-us",
    radius: 12,
    state: "Oregon",
    stateCode: "OR",
  },
  "puerto-rico": {
    coordinates: { lat: 18.241499445419944, lon: -66.51546111650295 },
    countryCode: "US",
    formattedLocation: "Puerto Rico, USA",
    geoSlug: "puerto-rico",
    radius: 250,
    state: "Puerto Rico",
    stateCode: "PR",
  },
  "san-diego-ca": {
    city: "San Diego",
    coordinates: { lat: 32.715738, lon: -117.1610838 },
    countryCode: "US",
    formattedLocation: "San Diego, CA",
    geoSlug: "san-diego-ca-us",
    radius: 24,
    state: "California",
    stateCode: "CA",
  },
  "san-francisco-ca": {
    city: "San Francisco",
    coordinates: { lat: 37.7749295, lon: -122.4194155 },
    countryCode: "US",
    formattedLocation: "San Francisco, CA",
    geoSlug: "san-francisco-ca-us",
    radius: 27,
    state: "California",
    stateCode: "CA",
  },
  "san-jose-ca": {
    city: "San Jose",
    coordinates: { lat: 37.3382082, lon: -121.8863286 },
    countryCode: "US",
    formattedLocation: "San Jose, CA",
    geoSlug: "san-jose-ca-us",
    radius: 18,
    state: "California",
    stateCode: "CA",
  },
  "seattle-wa": {
    city: "Seattle",
    coordinates: { lat: 47.6062095, lon: -122.3320708 },
    countryCode: "US",
    formattedLocation: "Seattle, WA",
    geoSlug: "seattle-wa-us",
    radius: 10,
    state: "Washington",
    stateCode: "WA",
  },
  "tempe-az": {
    city: "Tempe",
    coordinates: { lat: 33.4255104, lon: -111.9400054 },
    countryCode: "US",
    formattedLocation: "Tempe, AZ",
    geoSlug: "tempe-az-us",
    radius: 6,
    state: "Arizona",
    stateCode: "AZ",
  },
  texas: {
    coordinates: { lat: 31.9685988, lon: -99.9018131 },
    countryCode: "US",
    formattedLocation: "Texas, USA",
    geoSlug: "texas",
    radius: 528,
    state: "Texas",
    stateCode: "TX",
  },
  "tucson-az": {
    city: "Tucson",
    coordinates: { lat: 32.2226066, lon: -110.9747108 },
    countryCode: "US",
    formattedLocation: "Tucson, AZ",
    geoSlug: "tucson-az-us",
    radius: 15,
    state: "Arizona",
    stateCode: "AZ",
  },
  "virgin-islands": {
    coordinates: { lat: 17.733607835512924, lon: -64.79500575438237 },
    countryCode: "US",
    formattedLocation: "Virgin Islands, USA",
    geoSlug: "virgin-islands",
    radius: 250,
    state: "Virgin Islands",
    stateCode: "VI",
  },
  virginia: {
    coordinates: { lat: 37.4315734, lon: -78.6568942 },
    countryCode: "US",
    formattedLocation: "Virginia, USA",
    geoSlug: "virginia",
    radius: 251,
    state: "Virginia",
    stateCode: "VA",
  },
  washington: {
    city: "",
    coordinates: { lat: 47.7510741, lon: -120.7401385 },
    countryCode: "US",
    formattedLocation: "Washington, USA",
    geoSlug: "washington",
    radius: 219,
    state: "Washington",
    stateCode: "WA",
  },
};

export default locations;
