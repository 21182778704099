export const states = [
  { short: "al", slug: "alabama" },
  { short: "ak", slug: "alaska" },
  { short: "as", slug: "american-samoa" },
  { short: "az", slug: "arizona" },
  { short: "ar", slug: "arkansas" },
  { short: "ca", slug: "california" },
  { short: "co", slug: "colorado" },
  { short: "ct", slug: "connecticut" },
  { short: "de", slug: "delaware" },
  { short: "dc", slug: "district-of-columbia" },
  { short: "fl", slug: "florida" },
  { short: "ga", slug: "georgia" },
  { short: "gu", slug: "guam" },
  { short: "hi", slug: "hawaii" },
  { short: "id", slug: "idaho" },
  { short: "il", slug: "illinois" },
  { short: "in", slug: "indiana" },
  { short: "ia", slug: "iowa" },
  { short: "ks", slug: "kansas" },
  { short: "ky", slug: "kentucky" },
  { short: "la", slug: "louisiana" },
  { short: "me", slug: "maine" },
  { short: "md", slug: "maryland" },
  { short: "ma", slug: "massachusetts" },
  { short: "mi", slug: "michigan" },
  { short: "mn", slug: "minnesota" },
  { short: "ms", slug: "mississippi" },
  { short: "mo", slug: "missouri" },
  { short: "mt", slug: "montana" },
  { short: "ne", slug: "nebraska" },
  { short: "nv", slug: "nevada" },
  { short: "nh", slug: "new-hampshire" },
  { short: "nj", slug: "new-jersey" },
  { short: "nm", slug: "new-mexico" },
  { short: "ny", slug: "new-york" },
  { short: "nc", slug: "north-carolina" },
  { short: "nd", slug: "north-dakota" },
  { short: "mp", slug: "northern-mariana-islands" },
  { short: "oh", slug: "ohio" },
  { short: "ok", slug: "oklahoma" },
  { short: "or", slug: "oregon" },
  { short: "pa", slug: "pennsylvania" },
  { short: "pr", slug: "puerto-rico" },
  { short: "ri", slug: "rhode-island" },
  { short: "sc", slug: "south-carolina" },
  { short: "sd", slug: "south-dakota" },
  { short: "tn", slug: "tennessee" },
  { short: "tx", slug: "texas" },
  { short: "ut", slug: "utah" },
  { short: "vt", slug: "vermont" },
  { short: "va", slug: "virginia" },
  { short: "vi", slug: "virgin-islands" },
  { short: "wa", slug: "washington" },
  { short: "wv", slug: "west-virginia" },
  { short: "wi", slug: "wisconsin" },
  { short: "wy", slug: "wyoming" },
];

export default states;

export const stateData = {
  alabama: {
    coordinates: { lat: 32.3182314, lon: -86.902298 },
    country_code: "US",
    formattedLocation: "Alabama, USA",
    radius: 220,
    slug: "alabama",
    state: "Alabama",
    stateCode: "AL",
  },
  alaska: {
    coordinates: { lat: 64.2008413, lon: -149.4936733 },
    country_code: "US",
    formattedLocation: "Alaska, USA",
    radius: 709,
    slug: "alaska",
    state: "Alaska",
    stateCode: "AK",
  },
  "american-samoa": {
    coordinates: { lat: -14.298462, lon: -170.716407 },
    country_code: "AS",
    formattedLocation: "American Samoa, USA",
    radius: 250,
    slug: "american-samoa",
    state: "American Samoa",
    stateCode: "AS",
  },
  arizona: {
    coordinates: { lat: 34.0489281, lon: -111.0937311 },
    country_code: "US",
    formattedLocation: "Arizona, USA",
    radius: 235,
    slug: "arizona",
    state: "Arizona",
    stateCode: "AZ",
  },
  arkansas: {
    coordinates: { lat: 35.20105, lon: -91.8318334 },
    country_code: "US",
    formattedLocation: "Arkansas, USA",
    radius: 152,
    slug: "arkansas",
    state: "Arkansas",
    stateCode: "AR",
  },
  california: {
    coordinates: { lat: 36.778261, lon: -119.4179324 },
    country_code: "US",
    formattedLocation: "California, USA",
    radius: 459,
    slug: "california",
    state: "California",
    stateCode: "CA",
  },
  colorado: {
    coordinates: { lat: 39.5500507, lon: -105.7820674 },
    country_code: "US",
    formattedLocation: "Colorado, USA",
    radius: 222,
    slug: "colorado",
    state: "Colorado",
    stateCode: "CO",
  },
  connecticut: {
    coordinates: { lat: 41.6032207, lon: -73.087749 },
    country_code: "US",
    formattedLocation: "Connecticut, USA",
    radius: 74,
    slug: "connecticut",
    state: "Connecticut",
    stateCode: "CT",
  },
  delaware: {
    coordinates: { lat: 38.9108325, lon: -75.52766989999999 },
    country_code: "US",
    formattedLocation: "Delaware, USA",
    radius: 71,
    slug: "delaware",
    state: "Delaware",
    stateCode: "DE",
  },
  florida: {
    coordinates: { lat: 27.6648274, lon: -81.5157535 },
    country_code: "US",
    formattedLocation: "Florida, USA",
    radius: 249,
    slug: "florida",
    state: "Florida",
    stateCode: "FL",
  },
  georgia: {
    coordinates: { lat: 32.1656221, lon: -82.9000751 },
    country_code: "US",
    formattedLocation: "Georgia, USA",
    radius: 232,
    slug: "georgia",
    state: "Georgia",
    stateCode: "GA",
  },
  guam: {
    coordinates: { lat: 13.463270615786136, lon: 144.78657559584923 },
    country_code: "GU",
    formattedLocation: "Guam, USA",
    radius: 250,
    slug: "guam",
    state: "Guam",
    stateCode: "GU",
  },
  hawaii: {
    coordinates: { lat: 19.8967662, lon: -155.5827818 },
    country_code: "US",
    formattedLocation: "Hawaii, USA",
    radius: 599,
    slug: "hawaii",
    state: "Hawaii",
    stateCode: "HI",
  },
  idaho: {
    coordinates: { lat: 44.0682019, lon: -114.7420408 },
    country_code: "US",
    formattedLocation: "Idaho, USA",
    radius: 384,
    slug: "idaho",
    state: "Idaho",
    stateCode: "ID",
  },
  illinois: {
    coordinates: { lat: 40.6331249, lon: -89.3985283 },
    country_code: "US",
    formattedLocation: "Illinois, USA",
    radius: 179,
    slug: "illinois",
    state: "Illinois",
    stateCode: "IL",
  },
  indiana: {
    coordinates: { lat: 40.2671941, lon: -86.1349019 },
    country_code: "US",
    formattedLocation: "Indiana, USA",
    radius: 126,
    slug: "indiana",
    state: "Indiana",
    stateCode: "IN",
  },
  iowa: {
    coordinates: { lat: 41.8780025, lon: -93.097702 },
    country_code: "US",
    formattedLocation: "Iowa, USA",
    radius: 188,
    slug: "iowa",
    state: "Iowa",
    stateCode: "IA",
  },
  kansas: {
    coordinates: { lat: 39.011902, lon: -98.4842465 },
    country_code: "US",
    formattedLocation: "Kansas, USA",
    radius: 219,
    slug: "kansas",
    state: "Kansas",
    stateCode: "KS",
  },
  kentucky: {
    coordinates: { lat: 37.8393332, lon: -84.2700179 },
    country_code: "US",
    formattedLocation: "Kentucky, USA",
    radius: 155,
    slug: "kentucky",
    state: "Kentucky",
    stateCode: "KY",
  },
  louisiana: {
    coordinates: { lat: 30.9842977, lon: -91.96233269999999 },
    country_code: "US",
    formattedLocation: "Louisiana, USA",
    radius: 235,
    slug: "louisiana",
    state: "Louisiana",
    stateCode: "LA",
  },
  maine: {
    coordinates: { lat: 45.253783, lon: -69.4454689 },
    country_code: "US",
    formattedLocation: "Maine, USA",
    radius: 196,
    slug: "maine",
    state: "Maine",
    stateCode: "ME",
  },
  maryland: {
    coordinates: { lat: 39.0457549, lon: -76.64127119999999 },
    country_code: "US",
    formattedLocation: "Maryland, USA",
    radius: 101,
    slug: "maryland",
    state: "Maryland",
    stateCode: "MD",
  },
  massachusetts: {
    coordinates: { lat: 42.4072107, lon: -71.3824374 },
    country_code: "US",
    formattedLocation: "Massachusetts, USA",
    radius: 85,
    slug: "massachusetts",
    state: "Massachusetts",
    stateCode: "MA",
  },
  michigan: {
    coordinates: { lat: 44.3148443, lon: -85.60236429999999 },
    country_code: "US",
    formattedLocation: "Michigan, USA",
    radius: 323,
    slug: "michigan",
    state: "Michigan",
    stateCode: "MI",
  },
  minnesota: {
    coordinates: { lat: 46.729553, lon: -94.6858998 },
    country_code: "US",
    formattedLocation: "Minnesota, USA",
    radius: 303,
    slug: "minnesota",
    state: "Minnesota",
    stateCode: "MN",
  },
  mississippi: {
    coordinates: { lat: 32.3546679, lon: -89.3985283 },
    country_code: "US",
    formattedLocation: "Mississippi, USA",
    radius: 198,
    slug: "mississippi",
    state: "Mississippi",
    stateCode: "MS",
  },
  missouri: {
    coordinates: { lat: 37.9642529, lon: -91.8318334 },
    country_code: "US",
    formattedLocation: "Missouri, USA",
    radius: 235,
    slug: "missouri",
    state: "Missouri",
    stateCode: "MO",
  },
  montana: {
    coordinates: { lat: 46.8796822, lon: -110.3625658 },
    country_code: "US",
    formattedLocation: "Montana, USA",
    radius: 328,
    slug: "montana",
    state: "Montana",
    stateCode: "MT",
  },
  nebraska: {
    coordinates: { lat: 41.4925374, lon: -99.9018131 },
    country_code: "US",
    formattedLocation: "Nebraska, USA",
    radius: 258,
    slug: "nebraska",
    state: "Nebraska",
    stateCode: "NE",
  },
  nevada: {
    coordinates: { lat: 38.8026097, lon: -116.419389 },
    country_code: "US",
    formattedLocation: "Nevada, USA",
    radius: 255,
    slug: "nevada",
    state: "Nevada",
    stateCode: "NV",
  },
  "new-hampshire": {
    coordinates: { lat: 43.1938516, lon: -71.5723953 },
    country_code: "US",
    formattedLocation: "New Hampshire, USA",
    radius: 155,
    slug: "new-hampshire",
    state: "New Hampshire",
    stateCode: "NH",
  },
  "new-jersey": {
    coordinates: { lat: 40.0583238, lon: -74.4056612 },
    country_code: "US",
    formattedLocation: "New Jersey, USA",
    radius: 94,
    slug: "new-jersey",
    state: "New Jersey",
    stateCode: "NJ",
  },
  "new-mexico": {
    coordinates: { lat: 34.5199402, lon: -105.8700901 },
    country_code: "US",
    formattedLocation: "New Mexico, USA",
    radius: 236,
    slug: "new-mexico",
    state: "New Mexico",
    stateCode: "NM",
  },
  "new-york": {
    coordinates: { lat: 43.2994285, lon: -74.21793260000001 },
    country_code: "US",
    formattedLocation: "New York, USA",
    radius: 22,
    slug: "new-york",
    state: "New York",
    stateCode: "NY",
  },
  "north-carolina": {
    coordinates: { lat: 35.7595731, lon: -79.01929969999999 },
    country_code: "US",
    formattedLocation: "North Carolina, USA",
    radius: 211,
    slug: "north-carolina",
    state: "North Carolina",
    stateCode: "NC",
  },
  "north-dakota": {
    coordinates: { lat: 47.5514926, lon: -101.0020119 },
    country_code: "US",
    formattedLocation: "North Dakota, USA",
    radius: 228,
    slug: "north-dakota",
    state: "North Dakota",
    stateCode: "ND",
  },
  "northern-mariana-islands": {
    coordinates: { lat: 15.188468639158321, lon: 145.74681897305973 },
    country_code: "MP",
    formattedLocation: "Northern Mariana Islands, USA",
    radius: 250,
    slug: "northern-mariana-islands",
    state: "Northern Mariana Islands",
    stateCode: "MP",
  },
  ohio: {
    coordinates: { lat: 40.4172871, lon: -82.90712300000001 },
    country_code: "US",
    formattedLocation: "Ohio, USA",
    radius: 181,
    slug: "ohio",
    state: "Ohio",
    stateCode: "OH",
  },
  oklahoma: {
    coordinates: { lat: 35.0077519, lon: -97.092877 },
    country_code: "US",
    formattedLocation: "Oklahoma, USA",
    radius: 27,
    slug: "oklahoma",
    state: "Oklahoma",
    stateCode: "OK",
  },
  oregon: {
    coordinates: { lat: 43.8041334, lon: -120.5542012 },
    country_code: "US",
    formattedLocation: "Oregon, USA",
    radius: 265,
    slug: "oregon",
    state: "Oregon",
    stateCode: "OR",
  },
  pennsylvania: {
    coordinates: { lat: 41.2033216, lon: -77.1945247 },
    country_code: "US",
    formattedLocation: "Pennsylvania, USA",
    radius: 158,
    slug: "pennsylvania",
    state: "Pennsylvania",
    stateCode: "PA",
  },
  "puerto-rico": {
    coordinates: { lat: 18.248987173515932, lon: -66.59659994117366 },
    country_code: "US",
    formattedLocation: "Puerto Rico, USA",
    radius: 158,
    slug: "puerto-rico",
    state: "Puerto Rico",
    stateCode: "PR",
  },
  "rhode-island": {
    coordinates: { lat: 41.5800945, lon: -71.4774291 },
    country_code: "US",
    formattedLocation: "Rhode Island, USA",
    radius: 37,
    slug: "rhode-island",
    state: "Rhode Island",
    stateCode: "RI",
  },
  "south-carolina": {
    coordinates: { lat: 33.836081, lon: -81.1637245 },
    country_code: "US",
    formattedLocation: "South Carolina, USA",
    radius: 180,
    slug: "south-carolina",
    state: "South Carolina",
    stateCode: "SC",
  },
  "south-dakota": {
    coordinates: { lat: 43.9695148, lon: -99.9018131 },
    country_code: "US",
    formattedLocation: "South Dakota, USA",
    radius: 218,
    slug: "south-dakota",
    state: "South Dakota",
    stateCode: "SD",
  },
  tennessee: {
    coordinates: { lat: 35.5174913, lon: -86.5804473 },
    country_code: "US",
    formattedLocation: "Tennessee, USA",
    radius: 288,
    slug: "tennessee",
    state: "Tennessee",
    stateCode: "TN",
  },
  texas: {
    coordinates: { lat: 31.9685988, lon: -99.9018131 },
    country_code: "US",
    formattedLocation: "Texas, USA",
    radius: 482,
    slug: "texas",
    state: "Texas",
    stateCode: "TX",
  },
  utah: {
    coordinates: { lat: 39.3209801, lon: -111.0937311 },
    country_code: "US",
    formattedLocation: "Utah, USA",
    radius: 215,
    slug: "utah",
    state: "Utah",
    stateCode: "UT",
  },
  vermont: {
    coordinates: { lat: 44.5588028, lon: -72.57784149999999 },
    country_code: "US",
    formattedLocation: "Vermont, USA",
    radius: 64,
    slug: "vermont",
    state: "Vermont",
    stateCode: "VT",
  },
  "virgin-islands": {
    coordinates: { lat: 17.733607835512924, lon: -64.79500575438237 },
    country_code: "US",
    formattedLocation: "Virgin Islands, USA",
    radius: 250,
    slug: "virgin-islands",
    state: "Virgin Islands",
    stateCode: "VI",
  },
  virginia: {
    coordinates: { lat: 37.4315734, lon: -78.6568942 },
    country_code: "US",
    formattedLocation: "Virginia, USA",
    radius: 236,
    slug: "virginia",
    state: "Virginia",
    stateCode: "VA",
  },
  washington: {
    coordinates: { lat: 47.7510741, lon: -120.7401385 },
    country_code: "US",
    formattedLocation: "Washington, USA",
    radius: 196,
    slug: "washington",
    state: "Washington",
    stateCode: "WA",
  },
  "washington-dc": {
    city: "Washington",
    coordinates: { lat: 38.9071923, lon: -77.0368707 },
    country_code: "US",
    formattedLocation: "Washington, DC",
    radius: 10,
    slug: "washington-dc",
    state: "District of Columbia",
    stateCode: "DC",
  },
  "west-virginia": {
    coordinates: { lat: 38.5976262, lon: -80.4549026 },
    country_code: "US",
    formattedLocation: "West Virginia, USA",
    radius: 203,
    slug: "west-virginia",
    state: "West Virginia",
    stateCode: "WV",
  },
  wisconsin: {
    coordinates: { lat: 43.7844397, lon: -88.7878678 },
    country_code: "US",
    formattedLocation: "Wisconsin, USA",
    radius: 274,
    slug: "wisconsin",
    state: "Wisconsin",
    stateCode: "WI",
  },
  wyoming: {
    coordinates: { lat: 43.0759678, lon: -107.2902839 },
    country_code: "US",
    formattedLocation: "Wyoming, USA",
    radius: 210,
    slug: "wyoming",
    state: "Wyoming",
    stateCode: "WY",
  },
};
