import React, { createContext } from "react";

import { InitialConfigContext } from "custom-types/FinderPage";
import { RetailType } from "custom-types/Store";

export const finderConfigContext = {
  FAQData: { data: {} },
  cities: [
    {
      city: "",
      count: 0,
      distance: 0,
      distanceUnit: "",
      state: "",
    },
  ],
  copyBlocks: {
    copy_block: [
      { copy: "", header: "" },
      { copy: "", header: "" },
    ],
    location: { country: "" },
    status: "",
  },
  farAwayThreshold: 25,
  finderRoute: "/dispensaries",
  isLocationPage: false,
  organization: {},
  retailType: "dispensary" as RetailType,
  storeRoute: "/dispensary-info/",
  title: "Dispensaries",
};

const ConfigContext = createContext<InitialConfigContext>(finderConfigContext);

export const ConfigProvider = ({
  children,
  data,
}: {
  children: React.ReactNode;
  data: InitialConfigContext;
}) => <ConfigContext.Provider value={data}>{children}</ConfigContext.Provider>;

export default ConfigContext;
