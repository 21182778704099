import { NextParsedUrlQuery } from "next/dist/server/request-meta";

import locations from "constants/finder/locations";
import { AvailableSort } from "custom-types/AvailableFilters";
import { Location } from "custom-types/Location";
import { StoresResponse } from "custom-types/Store";
import { BoundingBox, calculateRadiusInMiles } from "utils/finder/mapUtils";
import initializeGoogleMapsGeocoder from "utils/maps/initializeGoogleMapsGeocoder";
import { clearQueryParams, setQueryParams } from "utils/nextRouterUtils";

export const MIN_RADIUS = 20;

/*
  Updates the query params to reflect the supplied sort or removes the query params if default sort is requested
*/
export const setSortQueryParam = (sortBy: AvailableSort) =>
  sortBy?.param
    ? setQueryParams(
        {
          sort: sortBy.param,
        },
        true,
        ["page"],
      )
    : clearQueryParams(["sort", "page"], true);

export const getLocationRadius = async (
  location: Location,
  minRadius = MIN_RADIUS,
): Promise<string> => {
  const { geoSlug = "", placeId, zip, city, state } = location;

  const potentialIndex = geoSlug.endsWith("-us")
    ? geoSlug.slice(0, geoSlug.lastIndexOf("-us"))
    : geoSlug;
  const radius = locations[potentialIndex]?.radius;

  const DEFAULT_RADIUS_MI = `${minRadius}mi`;

  if (radius) {
    if (radius < minRadius) {
      return DEFAULT_RADIUS_MI;
    }
    return `${radius}mi`;
  }

  const params = {
    ...(placeId && { placeId }),
    ...(!placeId && {
      componentRestrictions: {
        ...(zip && { postalCode: zip }),
        ...(city && { locality: city }),
        ...(state && {
          administrativeArea: state,
        }),
      },
    }),
  };

  const geocoder = await initializeGoogleMapsGeocoder();

  return new Promise((resolve) => {
    // GeocoderRequest takes `placeId` instead of `place_id`
    // GeocoderResponse returns `place_id` instead of `placeId`
    // Gee thanks Google

    geocoder?.geocode(params, (results, status) => {
      if (status === "OK" && results && results[0]?.geometry?.bounds) {
        try {
          const {
            geometry: { bounds },
          } = results[0];
          const center = bounds.getCenter();
          const ne = bounds.getNorthEast();

          const center_lat = center.lat();
          const center_lon = center.lng();
          const lat = ne.lat();
          const lon = ne.lng();

          const dis = calculateRadiusInMiles({
            center_lat,
            center_lon,
            lat,
            lon,
          });

          let boundedRadius = dis;

          if (dis < minRadius) {
            boundedRadius = minRadius;
          }

          resolve(`${boundedRadius}mi`);
        } catch {
          resolve(DEFAULT_RADIUS_MI);
        }
      } else {
        resolve(DEFAULT_RADIUS_MI);
      }
    });
  });
};

export const isAreaSearch = (
  { lat, lon, lng, zoom }: NextParsedUrlQuery,
  boundingBox?: BoundingBox | null,
) => lat && (lon || lng) && zoom && boundingBox;

export const finderNoResults: StoresResponse = {
  availableFilters: [],
  availableSorts: [],
  currentPage: 1,
  error: true,
  pageCount: 1,
  promotedNewStores: [],
  sponsoredStores: [],
  spotlight: null,
  stores: [],
  storesCount: 0,
};
