import logError from "utils/logError";

import initializeGoogleMaps from "./initializeGoogleMaps";

export default async (
  swOrLatLngBounds?:
    | google.maps.LatLng
    | null
    | google.maps.LatLngLiteral
    | google.maps.LatLngBounds,
  ne?: google.maps.LatLng | null | google.maps.LatLngLiteral,
) => {
  const isFunctionInitialized = () =>
    typeof google !== "undefined" && google?.maps?.LatLngBounds;
  try {
    if (!isFunctionInitialized()) {
      await initializeGoogleMaps();
    }

    if (isFunctionInitialized()) {
      return new google.maps.LatLngBounds(swOrLatLngBounds, ne);
    }
  } catch {
    logError("Could not intitialize google maps latlngbounds");
  }
};
