import { LatLngLiteral } from "@googlemaps/google-maps-services-js";
import { Bounds } from "google-map-react";

import { Coordinates } from "custom-types/Coordinates";
import { Store } from "custom-types/Store";
import initializeGoogleMapsLatLngBounds from "utils/maps/initializeGoogleMapsLatLngBounds";
import initializeGoogleMapsSpherical from "utils/maps/initializeGoogleMapsSpherical";
import { getTierName, tierNames } from "utils/tiers";

import { mapStyles } from "./mapStyleConfigs";

export type BoundingBox = Bounds;

export const getAllMapMarkers = async (stores: Store[]) => {
  const mapMarkers = await Promise.all(
    stores.map(async (store) =>
      store.hasDeliveryEnabled || store.flags.includes("delivery")
        ? await Promise.all(
            store.locations.map(
              async (location) =>
                await setStoreMapMarkerData({ location, store }),
            ),
          )
        : await setStoreMapMarkerData({
            location: store.primaryLocation,
            store,
          }),
    ),
  );

  return mapMarkers.flatMap((marker) => marker);
};

export const setStoreMapMarkerData = async ({
  store,
  location,
  premiumRank = null,
  overridePlatinum = null,
}: {
  store: Store;
  location: Coordinates;
  premiumRank?: string | null;
  overridePlatinum?: boolean | null;
}) => ({
  ...store,
  lat: location ? location.lat : store.location?.lat,
  lon: location ? location.lon : store.location?.lon,
  mapMarkerSize: await getMapMarkerSize(
    store,
    location || store.location,
    overridePlatinum,
  ),
  ...(premiumRank && { premiumRank }),
});

export const assignMapMarkers = async (
  stores: Store[],
  boundingBox: BoundingBox,
): Promise<Store[]> => {
  /*
   * LatLngBounds class
   * https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngBounds
   * params:
   * - southWest: {lat: number, lng: number}
   * - northEast: {lat: number, lng: number}
   *
   */
  const bounds = await initializeGoogleMapsLatLngBounds(
    boundingBox.sw,
    boundingBox.ne,
  );

  const mapMarkers = await Promise.all(
    stores.map(async (store) => {
      const { primaryLocation, locations } = store;

      const boundsCenter: LatLngLiteral = {
        lat: bounds?.getCenter().lat() || 0,
        lng: bounds?.getCenter().lng() || 0,
      };

      const uniqueLocations = getUniqueLocations(locations).filter(
        (location) =>
          location?.lat &&
          location?.lon &&
          bounds?.contains({ lat: location.lat, lng: location.lon }),
      );

      const visibleLocations = await Promise.all(
        uniqueLocations.map(async (location) => {
          let { premiumRank } = store;

          const overridePlatinum = await shouldOverridePlatinum({
            location,
            locations: uniqueLocations,
            point: boundsCenter,
            store,
          });

          if (overridePlatinum) {
            premiumRank = null;
          }

          return {
            ...(await setStoreMapMarkerData({
              location,
              overridePlatinum,
              store,
            })),
            premiumRank,
          };
        }),
      );

      if (visibleLocations.length === 0) {
        return [
          await setStoreMapMarkerData({ location: primaryLocation, store }),
        ];
      } else {
        return visibleLocations;
      }
    }),
  );

  return mapMarkers.flatMap((store) => store);
};

export const getMapMarkerSize = async (
  mapMarker: Store,
  location: Coordinates,
  overridePlatinum: boolean | null = null,
) => {
  let ignorePlatinumStatus = false;
  if (overridePlatinum === null) {
    ignorePlatinumStatus = await shouldOverridePlatinum({
      location,
      store: mapMarker,
    });
  } else {
    ignorePlatinumStatus = overridePlatinum;
  }
  const tierName = getTierName(mapMarker, ignorePlatinumStatus);
  if (tierName === tierNames.platinum) {
    return "xl";
  } else if (tierName && [tierNames.pro, tierNames.custom].includes(tierName)) {
    return "lg";
  } else if (
    tierName &&
    [tierNames.basic, tierNames.standard].includes(tierName)
  ) {
    return "md";
  } else {
    return "sm";
  }
};

export const getClosestLocation = async (
  store: Store,
  locations: Coordinates[],
  point: LatLngLiteral,
) => {
  const distanceFromCenter = await getDistanceBetween(point, {
    lat: store.locations[0].lat,
    lng: store.locations[0].lon,
  });

  return await locations.reduce(
    async (lastClosest, location) => {
      const { distanceFromCenter } = await lastClosest;
      const locationCoords: LatLngLiteral = {
        lat: location.lat,
        lng: location.lon,
      };

      const currentDistance = await getDistanceBetween(point, locationCoords);

      if (
        distanceFromCenter &&
        currentDistance &&
        distanceFromCenter > currentDistance
      ) {
        return {
          distanceFromCenter: currentDistance,
          location,
        };
      } else {
        return lastClosest;
      }
    },
    Promise.resolve({
      distanceFromCenter,
      location: store.locations[0],
    }),
  );
};

export const getClosestVisibleMapMarker = async (
  store: Store,
  center: google.maps.LatLngLiteral,
) => {
  if (!center) {
    throw new Error("center is required");
  }

  if (store?.locations?.length <= 1) {
    const firstLocation = store.locations[0];
    return setStoreMapMarkerData({ location: firstLocation, store });
  }

  const closest = await getClosestLocation(
    store,
    getUniqueLocations(store.locations),
    center,
  );

  let { premiumRank } = store;

  const overridePlatinum = await shouldOverridePlatinum({
    location: closest.location,
    store,
  });

  if (overridePlatinum) {
    premiumRank = null;
  }

  return setStoreMapMarkerData({
    location: closest.location,
    premiumRank,
    store,
  });
};

export const getUniqueLocations = (locations: Coordinates[]) => {
  const map: Coordinates[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  locations.forEach((location: any) => {
    if (
      !map.find(
        (entry) => entry.lat === location.lat && entry.lon === location.lon,
      )
    ) {
      map.push(location);
    }
  });

  return map;
};

const getDistanceBetween = async (
  a: google.maps.LatLng | google.maps.LatLngLiteral,
  b: google.maps.LatLng | google.maps.LatLngLiteral,
) => {
  const spherical = await initializeGoogleMapsSpherical();

  return spherical?.computeDistanceBetween(a, b);
};

export const createMapOptions = () => () => ({
  clickableIcons: false,
  disableDefaultUI: true,
  fullscreenControl: false,
  gestureHandling: "greedy",
  styles: mapStyles,
});

export const shouldOverridePlatinum = async ({
  store,
  location,
  locations,
  point,
}: {
  store: Store;
  location: Coordinates;
  locations?: Coordinates[];
  point?: LatLngLiteral;
}) => {
  if (!locations) {
    return false;
  }

  if (store?.locations?.length <= 1 || !store?.premiumRank) {
    return false;
  }

  if (!!locations && locations?.length >= 1 && !!point) {
    if (
      !locations.find(
        (location) =>
          location.lat === store?.primaryLocation.lat &&
          location.lon === store?.primaryLocation.lon,
      )
    ) {
      const closestLocation = await getClosestLocation(store, locations, point);
      if (
        closestLocation.location.lat === location.lat &&
        closestLocation.location.lon === location.lon
      ) {
        return false;
      }
    }
  }

  // floating point math is hard, so we'll compare locations
  // as strings until platinum placements are in zones and
  // there's better data for this

  return (
    truncateFloat(location.lat, 5) !==
      truncateFloat(store?.primaryLocation.lat, 5) ||
    truncateFloat(location.lon, 5) !==
      truncateFloat(store?.primaryLocation.lon, 5)
  );
};

const truncateFloat = (float: number, fixed: number) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return float.toString().match(re)?.[0];
};

export const calculateRadiusInMiles = ({
  lon,
  lat,
  center_lat,
  center_lon,
}: {
  lon: number;
  lat: number;
  center_lat: number;
  center_lon: number;
}) => {
  const r = 3963.0;

  // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
  const lat1 = center_lat / 57.2958;
  const lon1 = center_lon / 57.2958;
  const lat2 = lat / 57.2958;
  const lon2 = lon / 57.2958;

  // distance = circle radius from center to Northeast corner of bounds
  return (
    // eslint-disable-next-line prettier/prettier -- Prettier does not like the no-mixed-operators eslint rule, but we do :)
    r * Math.acos((Math.sin(lat1) * Math.sin(lat2)) + (Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)))
  );
};

// it its current version this util is pretty dumb and assumes the bounding box is
// a rectangle perpendicular to the equator. for our purposes at the time of writing this
// this is fine
export const isPointInBoundingBox = (
  point: Coordinates,
  boundingBox: BoundingBox,
) => {
  const isLaterallyInBounds =
    boundingBox.ne.lat >= point.lat && point.lat >= boundingBox.sw.lat;

  if (!isLaterallyInBounds) {
    return false;
  }

  const crosses180thMeridian = boundingBox.ne.lng < boundingBox.sw.lng;

  if (crosses180thMeridian) {
    return boundingBox.ne.lng >= point.lon || point.lon >= boundingBox.sw.lng;
  }
  return boundingBox.ne.lng >= point.lon && point.lon >= boundingBox.sw.lng;
};

export const radiusToZoom = (radius: number) => {
  // eslint-disable-next-line prettier/prettier -- Prettier does not like the no-mixed-operators eslint rule, but we do :)
  return Math.round(14 - (Math.log(Number(radius)) / Math.LN2));
};
