import logError from "utils/logError";

import initializeGoogleMaps from "./initializeGoogleMaps";

export default async (
  latOrLatLngOrLatLngLiteral:
    | number
    | google.maps.LatLngLiteral
    | google.maps.LatLng,
  lngOrNoClampNoWrap?: number | boolean | null,
  noClampNoWrap?: boolean,
) => {
  const isFunctionInitialized = () =>
    typeof google !== "undefined" && google?.maps?.LatLng;

  try {
    if (!isFunctionInitialized()) {
      await initializeGoogleMaps();
    }

    if (isFunctionInitialized()) {
      return new google.maps.LatLng(
        latOrLatLngOrLatLngLiteral,
        lngOrNoClampNoWrap,
        noClampNoWrap,
      );
    }
  } catch {
    logError("Could not intitialize google maps latlng");
  }
};
