import consumerApi from "api/services/consumer-api";
import { Location } from "custom-types/Location";
import { RetailType } from "custom-types/Store";
import logError from "utils/logError";

export type GetCitiesNearbyParams = {
  location: Location;
  countryCode: string;
  retailType: RetailType;
  medical?: boolean;
  recreational?: boolean;
  delivery_enabled?: boolean;
};

export type CitiesNearMeParams = {
  center_lat: number;
  center_lon: number;
  retail_type: RetailType;
  current_city: string;
  medical?: boolean;
  recreational?: boolean;
};

export type City = {
  city: string;
  count: number;
  distance: number;
  distanceUnit: string;
  state: string;
};

export const getCitiesNearby = async ({
  location,
  countryCode: country,
  medical,
  recreational,
  retailType: retail_type = "dispensary",
  delivery_enabled,
}: GetCitiesNearbyParams): Promise<City[]> => {
  try {
    const params = {
      lat: location.coordinates?.lat.toFixed(3),
      lon: location.coordinates?.lon.toFixed(3),
      retail_type,
      ...(location.city && { current_city: encodeURI(location.city) }),
      ...(medical && { medical: true }),
      ...(recreational && { recreational: true }),
      delivery_enabled,
    };

    const { data } = await consumerApi.get("/api/finder/v1/cities-near-me", {
      headers: { "X-Country-Code": country },
      params,
    });
    return data.data;
  } catch (error) {
    logError(error.message, {
      functionName: "getCitiesNearby",
      service: "finder",
      statusCode: error.statusCode,
    });
    return [];
  }
};

export default getCitiesNearby;
