import { Store } from "custom-types/Store";

export const tierLevels = {
  0: "premium",
  1: "premium",
  2: "premium",
  200: "pro",
  3: "premium",
  300: "standard",
  4: "premium",
  400: "basic",
  600: "starter",
  900: "info",
};

export const tierNames = {
  basic: "basic",
  custom: "custom",
  info: "info",
  platinum: "platinum",
  pro: "pro",
  standard: "standard",
  starter: "starter",
};

export const isPlatinum = (premiumRank?: Store["premiumRank"]) => {
  return !!(
    premiumRank &&
    ["diamond", "platinum", "gold", "silver", "bronze"].includes(premiumRank)
  );
};

export const getTierName = (store: Store, overridePlatinum?: boolean) => {
  const isStorePlatinum = !overridePlatinum && isPlatinum(store?.premiumRank);

  if (isStorePlatinum) {
    return tierNames.platinum;
  }
  const tierName = store?.mapMarkerLevel
    ? store?.mapMarkerLevel?.toLowerCase()
    : "basic";

  const storeImageUrl = store.logoImage || store.logoUrl;

  if (tierName === tierNames.custom && !storeImageUrl && store.tier) {
    return tierLevels[store.tier];
  }

  return tierName;
};
