// Intent for this is to house reusable functions to assist in Directus requests
// There is opportunity for graphQL requests in DirectusV9 that could also be useful here

import groupBy from "lodash/groupBy";

import { DirectusLocation } from "custom-types/Directus";

export const getFinderFilterForSeo = (
  filter: string[] | string | undefined,
) => {
  if (!Array.isArray(filter)) {
    // using this for canonical urls in Finder, might be overuse of this function
    const delimiter = "?filter=";
    switch (filter) {
      case "delivery":
        return `${delimiter}delivery`;
      case "black_owned":
        return `${delimiter}black_owned`;
      case "open_now":
        return `${delimiter}open_now`;
      case "medical":
        return `${delimiter}medical`;
      default:
        return "";
    }
  } else {
    return "";
  }
};

export const constructFinderFaqSlug = (
  path: string,
  filter: string[] | string | undefined,
) => {
  return `${path}${getFinderFilterForSeo(filter)}`;
};

/**
 * Returns the last directus result matching a given country, or the last matching the default country
 *
 * @param directusItems - list of directus items that have a country_code column
 * @param countryCode - The country code we're looking up in the data
 * @param [defaultCountryCode = "us"] - The country code to default to if we can't find 'countryCode'
 **/
export const filterByCountry = <T extends DirectusLocation>(
  directusItems: T[],
  countryCode: string,
  defaultCountryCode = "us",
) => {
  countryCode = countryCode.toLowerCase();
  defaultCountryCode = defaultCountryCode.toLowerCase();

  const itemsByCountry = groupBy(directusItems, (item) => {
    return item?.location?.country?.toLowerCase();
  });

  const results =
    // return the most recent entry for the country
    itemsByCountry[countryCode] || itemsByCountry[defaultCountryCode];
  return results ? results : directusItems;
};
