import { Store } from "custom-types/Store";

export const createSpotlightIndex = (spotlight: Store[]) =>
  spotlight.reduce((acc: Record<number, Store>, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

export const spotlightSetEqualToPreviousCycle = (
  spotlightIndex: Record<number, Store>,
  spotlightCycle: number[],
): boolean =>
  Object.keys(spotlightIndex)?.length === spotlightCycle?.length &&
  spotlightCycle?.every((id) => spotlightIndex[id] ?? false);

/**
 * Source: https://stackoverflow.com/a/46545530
 */
export const shuffleSpotlightIds = (spotlightIds: number[]) =>
  spotlightIds
    .map((value) => ({ sort: Math.random(), value }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export const selectSpotlight = (
  spotlight: Store[],
  spotlightCycle: number[],
  setSpotlightCycle: (value: number[]) => void,
) => {
  if (!Array.isArray(spotlight)) {
    return spotlight;
  } else if (!spotlight?.length) {
    return;
  }
  const spotlightIndex = createSpotlightIndex(spotlight);
  const [selectedSpotlightId, ...nextSpotlightIds] =
    spotlightSetEqualToPreviousCycle(spotlightIndex, spotlightCycle)
      ? spotlightCycle
      : shuffleSpotlightIds(spotlight.map((s) => s.id));
  setSpotlightCycle?.([...nextSpotlightIds, selectedSpotlightId]);
  return spotlightIndex[selectedSpotlightId];
};
