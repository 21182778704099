import { type AxiosError } from "axios";

import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";
import formatAndReturnRejectedError from "utils/formatAndReturnRejectedError";

// Initialize the service request with the app and environment
const finderApi = serviceRequest();

// Configure and set upstream URL
finderApi.defaults.baseURL = publicConfig.services.finderApi.url;

finderApi.interceptors.response.use(
  (response) => response,
  (
    error: AxiosError<{
      errors?: { errorCode: string; message: string; path: string }[];
      message: string;
    }>,
  ) =>
    formatAndReturnRejectedError(
      error,
      (error.response?.data?.errors?.length &&
        error.response.data.errors[0].message) ||
        error.response?.data?.message,
      error.response?.data?.errors?.length
        ? error.response.data.errors[0].errorCode
        : undefined,
    ),
);

export default finderApi;
