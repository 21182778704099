import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Bounds, ChangeEventValue, Coords } from "google-map-react";

import { Location } from "custom-types/Location";
import { Store } from "custom-types/Store";
import useWindowSize from "hooks/useWindowSize";
import { getFittedBounds } from "utils/finder/getFittedBounds";
import {
  assignMapMarkers,
  BoundingBox,
  getAllMapMarkers,
  getClosestVisibleMapMarker,
} from "utils/finder/mapUtils";
import slugifyName from "utils/slugifyName";

const noop = () => {};

export type NewMapSearchOptions = {
  trigger?: boolean;
  boundingBox?: BoundingBox;
};
export interface MapContextProps {
  boundingBox?: BoundingBox;
  handleMapChange: (data: ChangeEventValue) => void;
  handleMouseAwayStore: () => void;
  handleMouseOverStore: (storeId: number) => void;
  handleSetMapMarkers: (
    stores: Store[],
    bounds?: BoundingBox,
    isPickup?: boolean,
  ) => void;
  hoveredStore?: number;
  initMapMarkers: (
    location: Location,
    stores: Store[],
    isPickup: boolean,
  ) => void;
  loadingMapMarkers: boolean;
  mapCenter: { lat: number; lon: number };
  mapContainerEl?: HTMLDivElement;
  mapInitialized: boolean;
  mapMarkers: Store[];
  mapReady: boolean;
  selectedStore?: Store;
  setBoundingBox: (boundingBox?: BoundingBox) => void;
  setLoadingMapMarkers: (value: boolean) => void;
  setMapCenter: (center: { lat: number; lon: number }) => void;
  setMapContainerEl: (ref: HTMLDivElement) => void;
  setMapInitialized: (value: boolean) => void;
  setMapReady: (value: boolean) => void;
  setSelectedStore: (store?: Store | null) => void;
  setShowSearchButton: (value: boolean) => void;
  setSpotlight: Dispatch<SetStateAction<Store | undefined>>;
  setStaticMapURL: (url?: string) => void;
  setZoomLevel: (value: number) => void;
  showSearchButton: boolean;
  spotlight?: Store;
  staticMapURL?: string;
  zoomLevel: number;
}

export const mapContext = {
  handleMapChange: noop,
  handleMouseAwayStore: noop,
  handleMouseOverStore: noop,
  handleSetMapMarkers: noop,
  initMapMarkers: noop,
  loadingMapMarkers: false,
  mapCenter: { lat: 39.5, lon: -98.35 },
  mapInitialized: false,
  mapMarkers: [],
  mapReady: false,
  newMapSearchOptions: { trigger: false },
  setBoundingBox: noop,
  setLoadingMapMarkers: noop,
  setMapCenter: noop,
  setMapContainerEl: noop,
  setMapInitialized: noop,
  setMapReady: noop,
  setNewMapSearchOptions: noop,
  setSelectedStore: noop,
  setShowMobileListings: noop,
  setShowSearchButton: noop,
  setSpotlight: noop,
  setStaticMapURL: noop,
  setZoomLevel: noop,
  showMobileListings: false,
  showSearchButton: false,
  zoomLevel: 13,
};

const MapContext = createContext<MapContextProps>(mapContext);

MapContext.displayName = "FinderMapContext";

export const MapProvider = ({
  data,
  children,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  data: any;
  children: React.ReactNode;
}) => {
  const [mapInitialized, setMapInitialized] = useState(false);
  const [mapContainerEl, setMapContainerEl] = useState<HTMLDivElement>();
  const [boundingBox, setBoundingBox] = useState<Bounds>();
  const [mapCenter, setMapCenter] = useState({
    lat: data.center.lat,
    lon: data.center.lon,
  });
  const [zoomLevel, setZoomLevel] = useState(data.zoom || 13);
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [spotlight, setSpotlight] = useState<Store>();
  const [selectedStore, setSelectedStore] = useState(data.selectedStore);
  const [hoveredStore, setHoveredStore] = useState<number>();
  const [mapMarkers, setMapMarkers] = useState<Store[]>([]);
  const [loadingMapMarkers, setLoadingMapMarkers] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [staticMapURL, setStaticMapURL] = useState<string>();

  const size = useWindowSize();
  const isMobile = size.isMobile;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const handleMapChange = (data: any) => {
    const { center, zoom, bounds } = data;

    if (mapInitialized) {
      if (zoom !== zoomLevel) {
        setZoomLevel(zoom);
      }
    }

    if (center.lat !== mapCenter.lat || center.lng !== mapCenter.lon) {
      setMapCenter({ lat: center.lat, lon: center.lng });
    }

    setBoundingBox(bounds);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const handleStoreSelect = async (store: any) => {
    if (!store) {
      setSelectedStore(null);
      return;
    }

    if (isMobile) {
      const paginationCard = document.getElementById(
        `${slugifyName(store.name)}-pagination-card`,
      );
      if (paginationCard) {
        paginationCard.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      const listingCard = document.getElementById(
        `${slugifyName(store.name)}-listing-card`,
      );
      if (listingCard) {
        listingCard.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    if (store.lat !== undefined && store.lon !== undefined) {
      setSelectedStore({
        ...store,
      });
    } else {
      setSelectedStore({
        ...(await getClosestVisibleMapMarker(store, {
          lat: mapCenter.lat,
          lng: mapCenter.lon,
        })),
      });
    }
  };

  const handleMouseOverStore = (storeId: number) => {
    setHoveredStore(storeId);
  };

  const handleMouseAwayStore = () => {
    setHoveredStore(undefined);
  };

  const handleMapBounds = async (
    mapContainerEl: HTMLDivElement,
    coords: Coords,
    stores: Store[],
  ): Promise<BoundingBox> => {
    const { center, zoom, newBounds } = await getFittedBounds({
      center: coords,
      mapContainerEl,
      stores,
    });

    setMapCenter({ lat: center.lat, lon: center.lng });
    setZoomLevel(zoom);
    return newBounds;
  };

  const initMapMarkers = async (
    currUserLocation: Location,
    allStores: Store[],
    isPickup: boolean,
  ) => {
    if (allStores?.length === 0) {
      const { coordinates } = currUserLocation;
      handleSetMapMarkers(allStores, undefined, isPickup);
      setMapCenter({ lat: coordinates?.lat, lon: coordinates?.lon });
      return;
    }

    if (mapContainerEl) {
      const mapBounds = await handleMapBounds(
        mapContainerEl,
        {
          lat: currUserLocation.coordinates?.lat,
          lng: currUserLocation.coordinates?.lon,
        },
        allStores,
      );
      if (mapBounds) {
        return handleSetMapMarkers(allStores, mapBounds, isPickup);
      }
    }

    handleSetMapMarkers(allStores, undefined, isPickup);
  };

  const handleSetMapMarkers = async (
    stores: Store[],
    bounds?: Bounds,
    filterPickupLocations?: boolean,
  ) => {
    let filteredStores = stores;
    if (filterPickupLocations) {
      filteredStores = filteredStores.filter((store) => {
        store.locations = [];
        return store;
      });
    }
    const result = await getAllMapMarkers(filteredStores);
    const mapMarkers = bounds
      ? await assignMapMarkers(filteredStores, bounds)
      : result;

    setMapMarkers(mapMarkers);
    setLoadingMapMarkers(false);
  };

  return (
    <MapContext.Provider
      value={{
        boundingBox,
        handleMapChange,
        handleMouseAwayStore,
        handleMouseOverStore,
        handleSetMapMarkers,
        hoveredStore,
        initMapMarkers,
        loadingMapMarkers,
        mapCenter,
        mapContainerEl,
        mapInitialized,
        mapMarkers,
        mapReady,
        selectedStore,
        setBoundingBox,
        setLoadingMapMarkers,
        setMapCenter,
        setMapContainerEl,
        setMapInitialized,
        setMapReady,
        setSelectedStore: handleStoreSelect,
        setShowSearchButton,
        setSpotlight,
        setStaticMapURL,
        setZoomLevel,
        showSearchButton,
        spotlight,
        staticMapURL,
        zoomLevel,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapContext;
