import { GetServerSidePropsContext } from "next";
import { ParsedUrlQuery } from "querystring";

import getOrganization from "api/requests/consumerApi/getOrganization";
import getCitiesFaq from "api/requests/directus/getCitiesFaqs";
import { getCopyBlock } from "api/requests/directus/getCopyBlock";
import getCitiesNearby from "api/requests/getCitiesNearby";
import { CountryCode } from "custom-types/CountryCode";
import { DispensaryTypes } from "custom-types/Finder";
import {
  InitialContext,
  InitialLocationContext,
} from "custom-types/FinderPage";
import { Location } from "custom-types/Location";
import { RetailType } from "custom-types/Store";
import getFinderLocation from "utils/finder/contextData/getFinderLocation";
import getMapContext from "utils/finder/contextData/getMapContext";
import getStaticFinderConfig from "utils/finder/getStaticFinderConfig";
import { getCountryCodeHeader } from "utils/requestContext";

import { getFinderFilterForSeo } from "./directusRequestUtils";

export default async function getFinderContextData(
  context: GetServerSidePropsContext,
): Promise<InitialContext> {
  const { query, resolvedUrl } = context;

  const countryCode = getCountryCodeHeader(context.req.headers) as CountryCode;
  const city = getCity(query);
  const state = getState(query);
  const retailType = getRetailTypeFromUrl(resolvedUrl);
  const slug = `${resolvedUrl}${getFinderFilterForSeo(query?.filter)}`;

  const organizationCall = getOrganizationData(query);
  const finderLocationCall = getFinderLocation(
    city,
    state,
    countryCode,
    context,
  );
  const faqCall = getFaqData(resolvedUrl, query, countryCode, retailType);
  const copyBlockCall = getCopyBlock("copy_block", slug, countryCode);

  const [
    organization,
    { finderLocation, isLocationPage },
    FAQData,
    copyBlocks,
  ] = await Promise.all([
    organizationCall,
    finderLocationCall,
    faqCall,
    copyBlockCall,
  ]);

  const cities = await getCitiesNearby({
    countryCode,
    location: finderLocation as Location,
    retailType,
  });

  const locationContext: InitialLocationContext = {
    finderLocation,
  };

  const staticConfig = getStaticFinderConfig(countryCode, retailType);
  const configContext = {
    FAQData,
    cities,
    copyBlocks,
    isLocationPage,
    organization: organization || {},
    retailType,
    ...staticConfig,
  };

  const mapContext = getMapContext(query, finderLocation);

  return {
    configContext,
    locationContext,
    mapContext,
  } as InitialContext;
}

export const getMapDataFromContext = async (
  context: GetServerSidePropsContext,
) => {
  const { query } = context;

  const countryCode = getCountryCodeHeader(context.req.headers) as CountryCode;
  const city = getCity(query);
  const state = getState(query);
  const { finderLocation } = await getFinderLocation(
    city,
    state,
    countryCode,
    context,
  );
  return getMapContext(query, finderLocation);
};

function getCity(query: ParsedUrlQuery) {
  const city = query?.location?.[1];
  const state = query?.location?.[0];
  return state === "locations" ? undefined : city;
}

export function getState(query: ParsedUrlQuery) {
  const state = query?.location?.[0];
  return state === "locations" ? undefined : state;
}

export const getRetailTypeFromUrl = (pathname: string): RetailType => {
  const finderRoutes = Object.keys(DispensaryTypes);
  let retailType: RetailType = DispensaryTypes.dispensaries;
  finderRoutes.forEach((route) => {
    if (pathname.includes(route)) {
      retailType = DispensaryTypes[route as keyof typeof DispensaryTypes];
      return;
    }
  });

  return retailType;
};

async function getOrganizationData(query: ParsedUrlQuery) {
  const locations = query?.location?.[0];
  const organizationSlug = query?.location?.[1];
  return locations === "locations" && organizationSlug
    ? await getOrganization(organizationSlug)
    : null;
}

export async function getFaqData(
  resolvedUrl: string,
  query: ParsedUrlQuery,
  countryCode: CountryCode,
  retailType: RetailType,
) {
  const { draft = false, filter = [] } = query;
  return retailType === "dispensary"
    ? await getCitiesFaq(
        resolvedUrl.split("?")[0],
        !!draft,
        filter,
        countryCode,
      )
    : { data: {} };
}
