import Views from "constants/finder/config";
import { CountryCode } from "custom-types/CountryCode";
import { RetailType } from "custom-types/Store";

export default (countryCode: CountryCode, retailType: RetailType) => {
  const view = Views.find(
    (view) => view.countryCode === countryCode && view.type === retailType,
  );

  return {
    finderRoute: view?.finderRoute,
    storeRoute: view?.storeRoute,
    title: view?.title,
  };
};
