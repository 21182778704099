import { Bounds, fitBounds } from "google-map-react";

import { Store } from "custom-types/Store";
import initializeGoogleMapsLatLng from "utils/maps/initializeGoogleMapsLatLng";
import initializeGoogleMapsLatLngBounds from "utils/maps/initializeGoogleMapsLatLngBounds";

import { getClosestVisibleMapMarker } from "./mapUtils";

const FIT_BOUNDS_OFFSET = 0.001;

export type GetFittedBoundsParams = {
  mapContainerEl?: HTMLDivElement;
  center: google.maps.LatLngLiteral;
  stores: Store[];
  boundingBox?: Bounds | null;
};

export type FittedBounds = {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  newBounds: Bounds;
};

export const filterOutStores = (stores: Store[], excludes: number[]) =>
  stores.filter((store) => !excludes.includes(store.id));

export const getFittedBounds = async ({
  mapContainerEl,
  center,
  stores,
  boundingBox = null,
}: GetFittedBoundsParams): Promise<FittedBounds> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  let bounds: any;

  if (boundingBox) {
    const northWest = await initializeGoogleMapsLatLng(
      boundingBox.nw.lat,
      boundingBox.nw.lng,
    );
    const southEast = await initializeGoogleMapsLatLng(
      boundingBox.se.lat,
      boundingBox.se.lng,
    );
    bounds = await initializeGoogleMapsLatLngBounds(northWest, southEast);
  } else {
    bounds = await initializeGoogleMapsLatLngBounds();
    bounds.extend(await initializeGoogleMapsLatLng(center.lat, center.lng));

    const platinumStores = stores.filter((store) => !!store.premiumRank);

    const nonPlatinumStores = stores.filter((store) => !store.premiumRank);

    const filterPlatinums =
      nonPlatinumStores.length > 0 ? platinumStores.map(({ id }) => id) : [];

    const storesToFitBoundsTo = filterOutStores(stores, filterPlatinums);

    for (const store of storesToFitBoundsTo) {
      const closestLocation: Store = await getClosestVisibleMapMarker(store, {
        lat: center.lat,
        lng: center.lng,
      });
      // console.log(closestLocation.lat, closestLocation.lon);
      bounds.extend(
        await initializeGoogleMapsLatLng(
          closestLocation.lat as number,
          closestLocation.lon as number,
        ),
      );
    }
  }

  let width = 0;
  let height = 0;
  if (mapContainerEl) {
    const boundingRect = mapContainerEl.getBoundingClientRect();
    width = boundingRect.width;
    height = boundingRect.height;
  }

  let neLng = bounds.getNorthEast().lng();
  let neLat = bounds.getNorthEast().lat();
  let swLat = bounds.getSouthWest().lat();
  let swLng = bounds.getSouthWest().lng();

  // NOTE: We adjust the fitBounds on the off chance that the bounding box = 0,
  // as the Google Maps API cannot resolve a point inside a bounding box of 0
  if (swLng === neLng) {
    neLng += FIT_BOUNDS_OFFSET;
    swLng -= FIT_BOUNDS_OFFSET;
  }
  if (neLat === swLat) {
    neLat += FIT_BOUNDS_OFFSET;
    swLat -= FIT_BOUNDS_OFFSET;
  }

  return fitBounds(
    {
      ne: {
        lat: neLat,
        lng: neLng,
      },
      sw: {
        lat: swLat,
        lng: swLng,
      },
    },
    {
      height: height > 0 ? height : 500,
      width: width > 0 ? width : 900,
    },
  );
};
