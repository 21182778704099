import { useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import { getReloadPageAfterLocationChange } from "redux/selectors/header";
import getCurrentGeolocationPosition from "utils/getCurrentGeolocationPosition";

import LocationSearchIcon from "components/Icons/location_search.svg";

const Geolocator = ({ className }: { className?: string }) => {
  const shouldRefreshLocation = useSelector(getReloadPageAfterLocationChange);

  const { publishEvent } = useEventTracker();
  const [working, setWorking] = useState(false);

  const geolocatorClasses = classnames(
    "geolocator-button",
    className,
    "h-10 w-10 text-green flex items-center justify-center bg-white rounded-full shadow-low pointer-events-auto",
  );

  const dispatch = useDispatch();
  const onClick = () => {
    publishEvent({
      action: Action.click,
      category: Category.map,
      label: "detect location",
    });
    setWorking(true);

    getCurrentGeolocationPosition(
      dispatch,
      () => {
        setWorking(false);
      },
      { reloadPageOnSuccess: shouldRefreshLocation },
    );
  };

  return (
    <button
      title="click to use your location"
      className={geolocatorClasses}
      onClick={onClick}
    >
      <LocationSearchIcon
        className={working ? "animate-pulse" : ""}
        width="30"
        height="30"
      />
    </button>
  );
};

export default Geolocator;
