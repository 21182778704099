import { useState } from "react";

export type StorageType = "local" | "session";

function getWindowStorage(type: StorageType): Storage | null;
function getWindowStorage(type: string): Window | null;
function getWindowStorage(type: StorageType | string) {
  if (typeof window === "undefined") {
    return null;
  } else if (type === "local") {
    return window.localStorage;
  } else if (type === "session") {
    return window.sessionStorage;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    return window[type as any];
  }
}

function useBrowserStorage<T>(
  storageType: StorageType = "local",
  key: string,
  initialValue?: T,
): [T, (value: T | ((value: T) => T)) => void, () => void] {
  const windowStorage = getWindowStorage(storageType);
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!windowStorage) {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = windowStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to storage.
  const setValue = (value: T | ((value: T) => T)) => {
    if (!windowStorage) {
      return;
    }
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      windowStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.info(error);
    }
  };

  const clearValue = () => {
    if (!windowStorage) {
      return;
    }
    try {
      windowStorage.removeItem(key);
    } catch (error) {
      console.info(error);
    }
  };

  return [storedValue, setValue, clearValue];
}

export default useBrowserStorage;
