import { Store } from "custom-types/Store";
import { shouldOverridePlatinum } from "utils/finder/mapUtils";

import formatImgixUrl from "./image/formatImgixUrl";

export default (stores: Store[]) => {
  if (!stores) {
    return [];
  }
  stores.forEach((store) => {
    switch (store.premiumRank) {
      case "diamond":
        store.tier = 0;
        break;
      case "platinum":
        store.tier = 1;
        break;
      case "gold":
        store.tier = 2;
        break;
      case "silver":
        store.tier = 3;
        break;
      case "bronze":
        store.tier = 4;
        break;
      default:
        break;
    }

    store.location = getDefaultLocation(store);

    if (store.logoImage) {
      store.logoImage = formatImgixUrl(store.logoImage);
    }
  });

  return stores;
};

const getDefaultLocation = (store: Store) => {
  const { locations, tier } = store;

  if (tier && tier <= 3 && locations && locations.length > 1) {
    const primaryLocation = locations.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      async (location: any) =>
        !(await shouldOverridePlatinum({ location, store })),
    );
    return primaryLocation || locations[0];
  }

  return store.locations[0];
};
