import { Dispatch } from "redux";

import { Location } from "custom-types/Location";
import { updateLocation } from "redux/action-creators/location";

import { convertCoordinatesToLocation } from "./convertGoogleGeocoderToLocation";

const getCurrentGeolocationPosition = (
  dispatch: Dispatch,
  callback: (location: Location) => void,
  {
    onError,
    reloadPageOnSuccess,
  }: { onError?: (error: string) => void; reloadPageOnSuccess: boolean },
) => {
  const onGeolocateError = (message: string) => {
    alert(message);
    onError?.(message);
  };

  if (navigator?.geolocation) {
    const onGeolocationSuccess = async (position: GeolocationPosition) => {
      try {
        const formattedLocation = await convertCoordinatesToLocation(
          position.coords,
        );

        updateLocation(dispatch, formattedLocation);

        callback(formattedLocation);

        if (reloadPageOnSuccess) {
          window.location.reload();
        }
      } catch (e) {
        onGeolocateError(
          "Unable to set your current location. Please try again.",
        );
      }
    };

    // For explanation on error codes visit
    // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError#instance_properties
    const onGeolocationError = (error: GeolocationPositionError) => {
      let message = "";

      if (error.code === 1) {
        message =
          "You need to enable geolocation services in your browser to use this feature.";
      } else {
        message = "Unable to determine location. Please try again.";
      }

      onGeolocateError(message);
    };
    return navigator.geolocation.getCurrentPosition(
      onGeolocationSuccess,
      onGeolocationError,
    );
  } else {
    onGeolocateError("Your browser does not support this feature.");
  }
};

export default getCurrentGeolocationPosition;
