import { Dispatch, SetStateAction, useState } from "react";

import { tierNames } from "utils/tiers";

type Props = {
  logoImage: string;
  alt: string;
  tierName: string;
  isPlatinum: boolean;
  isDeliveryOnly?: boolean;
};

const MapMarkerImage = ({
  logoImage,
  alt,
  tierName,
  isPlatinum,
  isDeliveryOnly,
}: Props) => {
  const [useBackupImage, setUseBackupImage] = useState(false);

  if (!useBackupImage && (isPlatinum || tierName === tierNames.custom)) {
    return optimizeMapMarkerImage({
      alt,
      handleOnError: setUseBackupImage,
      isPlatinum,
      logoImage,
    });
  }

  if ([tierNames.pro, tierNames.custom].includes(tierName)) {
    if (isDeliveryOnly) {
      return (
        <svg
          className="absolute text-leafly-white p-1.5 map-marker__svg"
          height="85%"
        >
          <use href="#finder-delivery-icon" />
        </svg>
      );
    }

    return (
      <svg
        className="absolute text-leafly-white p-1.5 map-marker__svg"
        height="85%"
      >
        <use href="#finder-dispensary-icon-svg" />
      </svg>
    );
  }

  return null;
};

const DPR_SUPPORT = [1, 2, 3];

const optimizeMapMarkerImage = ({
  logoImage,
  alt,
  isPlatinum,
  handleOnError,
}: {
  logoImage: string;
  alt: string;
  isPlatinum: boolean;
  handleOnError: Dispatch<SetStateAction<boolean>>;
}) => {
  const optimizedSrc = `${logoImage}?auto=compress,format&w=${getImgSize(
    isPlatinum,
  )}`;
  return (
    <img
      alt={alt}
      srcSet={`${DPR_SUPPORT.map((dpr) => createDprSrc(optimizedSrc, dpr)).join(
        ",",
      )}`}
      src={optimizedSrc}
      className="map-marker__image h-auto absolute rounded-full bg-white lazyload"
      onError={() => {
        handleOnError(true);
      }}
    />
  );
};

const PLATINUM_IMG_SIZE = 60;
const CUSTOM_IMG_SIZE = 44;

const getImgSize = (isPlatinum: boolean) =>
  isPlatinum ? PLATINUM_IMG_SIZE : CUSTOM_IMG_SIZE;

const createDprSrc = (src: string, dpr: number) => `${src}&dpr=${dpr} ${dpr}x`;

export default MapMarkerImage;
