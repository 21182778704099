import directus from "api/services/directus";
import { FAQ } from "custom-types/Directus";
import {
  constructFinderFaqSlug,
  filterByCountry,
} from "utils/finder/directusRequestUtils";
import logError from "utils/logError";

const getCitiesFaq = async (
  path: string,
  isDraft: boolean,
  filter?: string | string[],
  countryCode = "US",
) => {
  const defaultCountryCode = "US";
  try {
    const params = {
      fields: "*, location.country",
      "filter[location][country][_in]": [
        countryCode.toUpperCase(),
        defaultCountryCode,
      ],
      "filter[slug][_eq]": constructFinderFaqSlug(path, filter),
    };

    const result = await directus.get<FAQ[]>("faq", { params });

    const data = filterByCountry(result.data, countryCode).find(
      (datum) => datum.status === (isDraft === true ? "draft" : "published"),
    );

    return { data: data || { faqs: [] } };
  } catch (e) {
    logError(e.message, {
      functionName: "getCitiesFaq",
      service: "directus",
      statusCode: e.statusCode,
    });

    return { data: { faqs: [] } };
  }
};

export default getCitiesFaq;
