export default [
  {
    countryCode: "CA",
    finderRoute: "/cbd-stores",
    storeRoute: "/cbd-store/",
    title: "CBD",
    type: "cbd-store",
  },
  {
    countryCode: "US",
    finderRoute: "/cbd-stores",
    storeRoute: "/cbd-store/",
    title: "CBD",
    type: "cbd-store",
  },
  {
    countryCode: "CA",
    finderRoute: "/medical-marijuana-doctors",
    storeRoute: "/doctors/",
    title: "Doctors",
    type: "clinic",
  },
  {
    countryCode: "US",
    finderRoute: "/medical-marijuana-doctors",
    storeRoute: "/doctors/",
    title: "Doctors",
    type: "clinic",
  },
  {
    countryCode: "CA",
    finderRoute: "/dispensaries",
    storeRoute: "/cannabis-store/",
    title: "Stores",
    type: "dispensary",
  },
  {
    countryCode: "US",
    finderRoute: "/dispensaries",
    storeRoute: "/dispensary-info/",
    title: "Dispensaries",
    type: "dispensary",
  },
];
