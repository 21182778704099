import { BoundingBox } from "./finder/mapUtils";

export const hasValidRequestBoundingBox = (boundingBox: BoundingBox) => {
  const {
    nw: { lat: topLeftLat, lng: topLeftLon },
    se: { lat: bottomRightLat, lng: bottomRightLon },
  } = boundingBox;

  const isValidNumber = (coordinate: number) =>
    !!coordinate && !isNaN(coordinate);

  const isValidLatCoordinate = (coordinate: number) => {
    if (!isValidNumber(coordinate)) {
      return false;
    }
    return coordinate <= 90 && coordinate >= -90;
  };

  const isValidLonCoordinate = (coordinate: number) => {
    if (!isValidNumber(coordinate)) {
      return false;
    }
    return coordinate <= 180 && coordinate >= -180;
  };

  const latCoordinatesValid = [topLeftLat, bottomRightLat].every((coordinate) =>
    isValidLatCoordinate(coordinate),
  );
  const lonCoordinatesValid = [topLeftLon, bottomRightLon].every((coordinate) =>
    isValidLonCoordinate(coordinate),
  );

  return latCoordinatesValid && lonCoordinatesValid;
};
