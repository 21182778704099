import { Location } from "./Location";

export enum DispensaryTypes {
  "dispensaries" = "dispensary",
  "cbd-stores" = "cbd-store",
  "medical-marijuana-doctors" = "clinic",
}

export type Street = {
  name: string;
  number: string;
};

export interface FinderBreadcrumbLocation extends Location {
  street: Street;
  state: string;
}

export enum ViewEnum {
  LIST = "list",
  MAP = "map",
  HALF = "half",
}

export type ViewType = ViewEnum.MAP | ViewEnum.LIST | ViewEnum.HALF;
